import CircularProgress from "@material-ui/core/CircularProgress";
import { PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MyDocument from "./MyDocument";

const Invoice = () => {
  const [invoiceData, setInvoiceData] = React.useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);

  React.useEffect(() => {
    document.title = "Profit And Loss Statement";
  }, []);

  const location = useLocation();

  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

  const getSummInfo = localStorage.getItem("pl_statement")
    ? JSON.parse(localStorage.getItem("pl_statement"))
    : null;

  useEffect(() => {
    if (location) {
      const getData = localStorage.getItem("company-info")
        ? JSON.parse(localStorage.getItem("company-info"))
        : null;
      setCompanyData(getData);
    }
  }, [location]);

  useEffect(() => {
    fetch("https://stepup-trade-api.clonestudiobd.com/api/companies")
      .then((res) => res.json())
      .then((data) => {
        const filterdData = data.data.filter(
          (item) => item.id === companyData?.id
        );
        setCompanyInfo(filterdData);
      });
  }, [companyData]);

  React.useEffect(() => {
    if (getSummInfo) {
      fetch(`https://stepup-trade-api.clonestudiobd.com/api/doc/pl_statement`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(getSummInfo),
      }).then((response) => {
        response.json().then((result) => {
          setInvoiceData(result[0]);
        });
      });
    }
  }, []);

  return (
    <PDFViewer
      style={{
        minHeight: "calc(100vh - 10px)",
        minWidth: "calc(100vw - 12px)",
      }}
      showToolbar={true}
    >
      {invoiceData ? (
        <MyDocument
          invoiceData={invoiceData ? invoiceData : []}
          companyInfo={companyInfo}
          getInfo={getInfo}
          getSummInfo={getSummInfo}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh-70px)",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
    </PDFViewer>
  );
};

export default Invoice;

// <div
//   style={{
//     minHeight: "100vh",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   }}
// >
//   <CircularProgress />
// </div>
