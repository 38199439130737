import { Divider, Menu, MenuItem, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import NestedMenuItem from "material-ui-nested-menu-item";
import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Header, NotificationCenter, Sidebar, Workspace } from "../components";
import axiosClient from "../config/axiosClient";
import useMountEffect from "../mountEffect";
import { downloadModulesAction } from "../redux/actions/menuActions";
import routes from "../routes";
import { MobileBreakpoint } from "../styleVariables";

//Components
const Users = lazy(() => import("../pages/modules/AdminPanel/Users"));
const Suppliers = lazy(() => import("../pages/modules/Settings/Suppliers"));
const LoadingPoints = lazy(() =>
  import("../pages/modules/Settings/LoadingPoint")
);
const Companies = lazy(() => import("../pages/modules/Settings/Companies"));

const UserAccessControl = lazy(() =>
  import("../pages/modules/AdminPanel/UserAccessControl")
);
const AppUserRole = lazy(() =>
  import("../pages/modules/AdminPanel/AppUserRole")
);

const DashboardHome = lazy(() => import("../pages/modules/Dashboard"));

const UserLevels = lazy(() => import("../pages/modules/AdminPanel/UserLevels"));
const UserLevelPrivileges = lazy(() =>
  import("../pages/modules/AdminPanel/UserLevelPrivileges")
);

const AppMenus = lazy(() => import("../pages/modules/AdminPanel/AppMenus"));

const Categories = lazy(() => import("../pages/modules/Product/Categories"));

const SubCategories = lazy(() =>
  import("../pages/modules/Product/SubCategories")
);

const Products = lazy(() => import("../pages/modules/Product/Products"));

const MeasuringUnits = lazy(() =>
  import("../pages/modules/Settings/MeasuringUnits")
);

const Payments = lazy(() => import("../pages/modules/Accounts/Payments"));
const ContraVoucher = lazy(() =>
  import("../pages/modules/Accounts/ContraVoucher")
);
const PaymentReceived = lazy(() =>
  import("../pages/modules/Accounts/PaymentsReceived")
);
const Accounts = lazy(() => import("../pages/modules/Settings/Accounts"));
const Customers = lazy(() => import("../pages/modules/Settings/Customers"));

const Sales = lazy(() => import("../pages/modules/Transitions/Sales"));
const Purchase = lazy(() => import("../pages/modules/Transitions/Purchase"));
const ProductBrands = lazy(() =>
  import("../pages/modules/Product/ProductBrands")
);
const PurchaseCreate = lazy(() =>
  import("../pages/modules/Transitions/Purchase/Create")
);
const SalesCreate = lazy(() =>
  import("../pages/modules/Transitions/Sales/Create")
);

const SupplierLedger = lazy(() => import("../pages/Reports/SupplierLedger"));
const StockLedger = lazy(() => import("../pages/Reports/ItemStockLedger"));
const StockSummary = lazy(() => import("../pages/Reports/StockSummary"));

const CustomerLedger = lazy(() => import("../pages/Reports/CustomerLedger"));
const ItemWiseCustomer = lazy(() =>
  import("../pages/Reports/ItemWiseCustomer")
);
const SalesRegister = lazy(() => import("../pages/Reports/SalesRegister"));
const OutStandingRep = lazy(() => import("../pages/Reports/OutStandingRep"));

const PurchaseRegister = lazy(() =>
  import("../pages/Reports/PurchaseRegister")
);

const DayBookStatus = lazy(() => import("../pages/Reports/DayWiseStatus"));
const PLStatement = lazy(() => import("../pages/Reports/Profit&LossStatement"));
const ItemPLStatement = lazy(() => import("../pages/Reports/ItemWisePL"));
const BSStatement = lazy(() =>
  import("../pages/Reports/BalanceSheetStatement")
);

const DOLedger = lazy(() => import("../pages/Reports/DOLedger"));
const CashBankBook = lazy(() => import("../pages/Reports/CashBankBook"));

const useStyles = makeStyles((theme) => ({
  panel: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      overflow: "auto",
      minHeight: "calc(100vh - 64px)",
      paddingTop: "64px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      overflow: "auto",
      minHeight: "calc(100vh - 56px)",
      paddingTop: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 64px)",
    },
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(1) * 2,
    right: theme.spacing(1) * 3,
  },
}));

const Dashboard = ({ history }) => {
  const classes = useStyles();
  const dispatch2 = useDispatch();
  const [menu, setMenu] = useState([]);
  const [opened, setOpened] = useState(true);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [toggleMenuWithHover, setToggleMenuWithHover] = useState(null);
  const [quantity, setQuantity] = React.useState(1);
  const modules = useSelector((state) => state?.menuList?.modules);

  React.useEffect(() => {
    if (userDetails) {
      dispatch2(downloadModulesAction(userDetails));
    }
  }, [userDetails]);

  function handleClose() {
    setToggleMenuWithHover(null);
  }

  const mediaMatcher = matchMedia(`(max-width: ${MobileBreakpoint}px)`);

  const resizeDispatch = () => {
    if (typeof Event === "function") {
      window.dispatchEvent(new Event("resize"));
    } else {
      const evt = window.document.createEvent("UIEvents");
      evt.initUIEvent("resize", true, false, window, 0);
      window.dispatchEvent(evt);
    }
  };

  const handleDrawerToggle = () => {
    setOpened(!opened);
    resizeDispatch();
  };

  const handleNotificationToggle = () =>
    setNotificationsOpen(!notificationsOpen);

  const handleFullscreenToggle = () => {
    const element = document.querySelector("#root");
    const isFullscreen =
      document.webkitIsFullScreen || document.mozFullScreen || false;

    element.requestFullScreen =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      function () {
        return false;
      };
    document.cancelFullScreen =
      document.cancelFullScreen ||
      document.webkitCancelFullScreen ||
      document.mozCancelFullScreen ||
      function () {
        return false;
      };
    isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
  };

  const handleSpeedDialOpen = () => setOpenSpeedDial(true);

  const handleSpeedDialClose = () => setOpenSpeedDial(false);

  const scrollRef = useRef();

  const handleBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getRoutes = (
    <Switch>
      <Route path="/app/menus">
        <AppMenus userDetails={userDetails} />
      </Route>

      <Route path="/app/dashboard">
        <DashboardHome userDetails={userDetails} />
      </Route>

      {/* Transition */}
      <Route path="/app/purchase_list">
        <Purchase userDetails={userDetails} />
      </Route>

      <Route path="/app/purchase_create">
        <PurchaseCreate
          userDetails={userDetails}
          toggleDrawer={handleDrawerToggle}
        />
      </Route>

      <Route path="/app/sales">
        <Sales userDetails={userDetails} />
      </Route>
      <Route path="/app/sale/create">
        <SalesCreate
          userDetails={userDetails}
          toggleDrawer={handleDrawerToggle}
        />
      </Route>

      {/* Accounts */}
      <Route path="/app/payments">
        <Payments userDetails={userDetails} />
      </Route>
      <Route path="/app/contra_voucher">
        <ContraVoucher userDetails={userDetails} />
      </Route>
      <Route path="/app/receive_voucher">
        <PaymentReceived userDetails={userDetails} />
      </Route>

      {/* Reports */}
      <Route path="/app/supplier_ledger">
        <SupplierLedger userDetails={userDetails} />
      </Route>
      <Route path="/app/stock_summary">
        <StockSummary userDetails={userDetails} />
      </Route>
      <Route path="/app/stock_ledger">
        <StockLedger userDetails={userDetails} />
      </Route>

      <Route path="/app/customer_ledger">
        <CustomerLedger toggleDrawer={handleDrawerToggle} />
      </Route>

      <Route path="/app/item_wise_customer_statement">
        <ItemWiseCustomer toggleDrawer={handleDrawerToggle} />
      </Route>

      <Route path="/app/sales_register">
        <SalesRegister userDetails={userDetails} />
      </Route>
      <Route path="/app/purchase_register">
        <PurchaseRegister userDetails={userDetails} />
      </Route>

      <Route path="/app/outstanding_report">
        <OutStandingRep userDetails={userDetails} />
      </Route>

      <Route path="/app/day_wise_status">
        <DayBookStatus toggleDrawer={handleDrawerToggle} />
      </Route>

      <Route path="/app/pl_statement">
        <PLStatement toggleDrawer={handleDrawerToggle} />
      </Route>
      <Route path="/app/item_wise_pl">
        <ItemPLStatement toggleDrawer={handleDrawerToggle} />
      </Route>

      <Route path="/app/bs_statement">
        <BSStatement toggleDrawer={handleDrawerToggle} />
      </Route>

      <Route path="/app/do_ledger">
        <DOLedger toggleDrawer={handleDrawerToggle} />
      </Route>

      <Route path="/app/cash_bank_book">
        <CashBankBook toggleDrawer={handleDrawerToggle} />
      </Route>

      {/* Product */}
      <Route path="/app/categories">
        <Categories userDetails={userDetails} />
      </Route>

      <Route path="/app/sub_categories">
        <SubCategories userDetails={userDetails} />
      </Route>

      <Route path="/app/product_brands">
        <ProductBrands userDetails={userDetails} />
      </Route>

      <Route path="/app/products">
        <Products userDetails={userDetails} />
      </Route>

      {/* Settings */}
      <Route path="/app/suppliers">
        <Suppliers userDetails={userDetails} />
      </Route>
      <Route path="/app/customers">
        <Customers userDetails={userDetails} />
      </Route>

      <Route path="/app/loading_point">
        <LoadingPoints userDetails={userDetails} />
      </Route>

      <Route path="/app/measuring_units">
        <MeasuringUnits userDetails={userDetails} />
      </Route>

      <Route path="/app/companies">
        <Companies userDetails={userDetails} />
      </Route>

      <Route path="/app/accounts">
        <Accounts userDetails={userDetails} />
      </Route>

      {/* Admin Panel */}

      <Route path="/app/apps_users">
        <Users userDetails={userDetails} />
      </Route>

      <Route path="/app/user_levels">
        <UserLevels userDetails={userDetails} />
      </Route>

      <Route path="/app/user_level-privileges">
        <UserLevelPrivileges userDetails={userDetails} />
      </Route>

      <Route path="/app/user_access_control">
        <UserAccessControl userDetails={userDetails} />
      </Route>

      <Route path="/app/user_roles">
        <AppUserRole userDetails={userDetails} />
      </Route>

      {/* <Redirect to="/404" /> */}
      <Redirect exact from="/app" to="/app/dashboard" />
    </Switch>
  );

  useMountEffect(() => {
    if (mediaMatcher.matches) setOpened(false);
    mediaMatcher.addListener((match) => {
      setTimeout(() => {
        if (match.matches) setOpened(false);
        else setOpened(true);
      }, 300);
    });

    const unlisten = history.listen(() => {
      if (mediaMatcher.matches) setOpened(false);
      document.querySelector("#root > div > main").scrollTop = 0;
    });

    return () => {
      unlisten();
      mediaMatcher.removeListener((match) => {
        setTimeout(() => {
          if (match.matches) setOpened(false);
          else setOpened(true);
        }, 300);
      });
    };
  });

  React.useEffect(() => {
    const getToken = localStorage.getItem("dm-userInfo")
      ? JSON.parse(localStorage.getItem("dm-userInfo"))
      : null;

    const token = "Bearer " + getToken?.token;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    };

    axiosClient.get("/auth/login/user", config).then((response) => {
      setUserDetails(response?.data?.data);
      localStorage.setItem("user-info", JSON.stringify(response?.data?.data));
    });

    // fetch(`https://stepup-trade-api.clonestudiobd.com/api/auth/login/user`, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //     Authorization: token,
    //   },
    // }).then((response) => {
    //   response.json().then((result) => {
    //     setUserDetails(result?.data);
    //   });
    // });
  }, []);

  const token = localStorage.getItem("dm-userInfo");

  useEffect(() => {
    switch (toggleMenuWithHover) {
      case "Product":
        setQuantity(7);
        break;

      case "Purchase":
        setQuantity(6.1);
        break;
      case "Expense":
        setQuantity(4.3);
        break;
      case "Settings":
        setQuantity(2.6);
        break;
      case "Admin Panel":
        setQuantity(1);
        break;
      default:
        setQuantity(1);
    }

    const filteringMenu =
      modules &&
      modules.length &&
      modules.filter((item) => item?.menu_caption === toggleMenuWithHover);
    setMenu(filteringMenu?.[0]?.children);
  }, [toggleMenuWithHover]);

  const handleClick = (menu_link) => {
    history.push(`/app${menu_link}`);
  };

  const recursiveMenu = (data) => {
    return (
      data &&
      data?.map(({ menu_caption, menu_link, children = [] }) => {
        if (!children?.length) {
          return (
            <MenuItem
              key={Math.random()}
              onClick={() => handleClick(menu_link)}
            >
              <Typography style={{ fontSize: 13 }}>{menu_caption}</Typography>
            </MenuItem>
          );
        }

        return (
          <NestedMenuItem
            arrow={false}
            style={{ fontSize: 13 }}
            label={menu_caption}
            parentMenuOpen={true}
          >
            <Typography
              style={{
                fontSize: 13,
                padding: 10,
                marginTop: -8,
                fontWeight: "bold",
                backgroundColor: "lightgray",
              }}
            >
              {menu_caption}
            </Typography>

            {children && children.length
              ? children?.map(({ menu_caption, menu_link, children = [] }) => {
                  return (
                    <MenuItem
                      key={Math.random() + 10}
                      onClick={() => handleClick(menu_link)}
                    >
                      <Typography style={{ fontSize: 13 }}>
                        {menu_caption}
                      </Typography>
                    </MenuItem>
                  );
                })
              : null}
          </NestedMenuItem>
        );
      })
    );
  };

  if (!token) {
    return <Redirect to="/signin" />;
  } else {
    return (
      <>
        {userDetails && modules && modules.length ? (
          <>
            <Header
              logoAltText="Primer Admin Template"
              logo={`${process.env.PUBLIC_URL}/static/images/logo.svg`}
              toggleDrawer={handleDrawerToggle}
              toogleNotifications={handleNotificationToggle}
              toggleFullscreen={handleFullscreenToggle}
              userDetails={userDetails}
            />
            <div
              className={classNames(classes.panel, "theme-dark")}
              ref={scrollRef}
              style={{ position: "relative" }}
            >
              <Sidebar
                routes={routes.items}
                opened={opened}
                setToggleMenuWithHover={setToggleMenuWithHover}
                handleClose={handleClose}
                toggleDrawer={handleDrawerToggle}
                userDetails={userDetails}
                modules={modules}
              />
              <Workspace opened={opened} toggleDrawer={handleDrawerToggle}>
                <div>
                  {toggleMenuWithHover && !opened && menu?.length ? (
                    <Menu
                      id="simple-menu"
                      keepMounted
                      open={true}
                      style={{ marginLeft: 65, top: -`${50 * quantity}` }}
                      onClose={handleClose}
                      MenuListProps={{ onMouseLeave: handleClose }}
                    >
                      <Typography
                        style={{
                          fontSize: 13,
                          padding: 10,
                          marginTop: -8,
                          fontWeight: "bold",
                          backgroundColor: "lightgray",
                        }}
                      >
                        {toggleMenuWithHover}
                      </Typography>
                      <Divider />
                      {recursiveMenu(menu)}
                    </Menu>
                  ) : null}
                </div>

                <Suspense
                  fallback={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "calc(100vh-70px)",
                      }}
                    >
                      <CircularProgress color="secondary" />
                    </div>
                  }
                >
                  {getRoutes}
                </Suspense>
              </Workspace>
              <NotificationCenter
                notificationsOpen={notificationsOpen}
                toogleNotifications={handleNotificationToggle}
              />
            </div>
            {/* 
         <Hidden xsDown>
        <SpeedDial
          ariaLabel="Settings"
          className={classes.speedDial}
          icon={<SpeedDialIcon icon={<SettingsIcon />} />}
          onBlur={handleSpeedDialClose}
          onClose={handleSpeedDialClose}
          onFocus={handleSpeedDialOpen}
          onMouseEnter={handleSpeedDialOpen}
          onMouseLeave={handleSpeedDialClose}
          open={openSpeedDial}
        >
          <SpeedDialAction
            icon={<WbSunnyIcon />}
            tooltipTitle="Toggle light/dark theme"
            onClick={() => dispatch({ type: "type" })}
          />
          <SpeedDialAction
            icon={
              state.direction === "rtl" ? (
                <FormatTextdirectionLToRIcon />
              ) : (
                <FormatTextdirectionRToLIcon />
              )
            }
            tooltipTitle="Toggle LTR/RTL direction"
            onClick={() => dispatch({ type: "direction" })}
          />
        </SpeedDial>
      </Hidden>  */}
          </>
        ) : (
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </>
    );
  }
};

export default Dashboard;
