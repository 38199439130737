import axios from "axios";

const axiosClient = axios.create({
  // baseURL: "https://stepup-api.mnbtradersbd.com/api/",
  // baseURL: "https://stepup-trade-api.clonestudiobd.com/api/",
  baseURL: "https://stepup-trade-api.clonestudiobd.com/api/",
  // baseURL: "https://stepup-trade-api.clonestudiobd.com/api/",
});

export default axiosClient;
